import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import Embed from "./Embed";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import SignIn from "./Login";
import Grid from "@mui/material/Grid";

function DashViewer(props: any) {
  let { slug } = useParams();

  const [dashboardEntity, setDashboardEntity] = useState<any | null>(null);
  const [displayedEmbedRank, setDisplayedEmbedRank] = useState("0");
  const [embedNumber, setEmbedNumber] = useState(0);
  const { currentUser } = useAuth();

  const [loggedIn, setLoggedIn] = useState(false);
  const [showLoginError, setShowLoginError] = useState(false);
  const [triedLogin, setTriedLogin] = useState(false);
  const [loginInfo, setLoginInfo] = useState({ user: "", password: "" });

  useEffect(() => {
    const initialize = () => {
      if (!triedLogin) {
        document.title = "Dashboard"
      }
    };
    return initialize();
  }, []);

  useEffect(() => {
    const initialize = () => {
      if (!triedLogin) {
        return;
      }
      // get access token to auth to backend then fetch (with token in header?)
      fetch("/dashboards/viewer/", {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Basic ${btoa(
            `${loginInfo.user}:${loginInfo.password}`
          )}`,
        },
        body: JSON.stringify({ slug: slug }),
      })
        .then(function (response) {
          if (response.status !== 200) {
            throw new Error(response.status.toString());
          } else {
            return response;
          }
        })
        .then((response) => response.json())
        .then((responseData) => {
          document.title = responseData.data.dash_infos.meta.name;
          setDashboardEntity(responseData.data.dash_infos);
          setEmbedNumber(
            responseData.data.dash_infos.embeds.authorizations.length
          );
          setLoggedIn(true);
        })
        .catch(function (error) {
          setLoggedIn(false);
          setShowLoginError(true);
        });
    };
    return initialize();
  }, [loginInfo]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    // this is done to avoid re-rendering the iframe each time as it first makes a post request to domo get the iframe
    // which triggers a refresh
    setDisplayedEmbedRank(newValue);
    const div_id_toshow = "embed-" + newValue;
    const divToShow: any = document.getElementById(div_id_toshow);
    divToShow.style.display = "block";

    for (var i = 0; i < embedNumber; i++) {
      if (i != parseInt(newValue)) {
        const divToHide: any = document.getElementById("embed-" + i);
        divToHide.style.display = "none";
      }
    }
  };

  return loggedIn ? (
    <Stack spacing={0}>
      <Grid container spacing={4} alignItems="center" justifyContent="center">
        <Grid item xs={1.2}>
          <img
            src={"/static/webo_logo.png"}
            alt="Webo Logo"
            style={{ width: "99%", height: "99%" }}
          />
        </Grid>

        <Grid item xs={10}>
          <TabContext value={displayedEmbedRank}>
            <Box
              sx={{ borderBottom: 0 }}
              alignItems="center"
              justifyContent="center"
            >
              <TabList onChange={handleChange} centered>
                {dashboardEntity != null &&
                  dashboardEntity.embeds.authorizations.map(
                    (embed: any, rank: any) => (
                      <Tab label={embed.label} value={rank} />
                    )
                  )}
              </TabList>
            </Box>
          </TabContext>
        </Grid>
      </Grid>

      {dashboardEntity != null && 
        dashboardEntity.embeds.authorizations.map((embed: any, rank: any) => (
          <Embed name={dashboardEntity.meta.name} i={rank} slug= {dashboardEntity.meta.slug}/>
        ))}
    {/* <p>{console.log("dashboardEntity.meta",dashboardEntity)}</p> */}
    </Stack>
    
  ) : (
    <SignIn
      setLoginInfo={setLoginInfo}
      showLoginError={showLoginError}
      setTriedLogin={setTriedLogin}
    />
  );
}

export default DashViewer;
