import React from "react"

function Iframe(props:any) {
    return (
        <iframe
        name={`embed-${props.i}`}
        frameBorder="0"
        scrolling="no"
        src={`/dashboards/viewer/${props.name}/${props.i}${props.slug}/`}
        style={{
          display: "block",
          background: "#1F3B50",
          height: "calc(100vh - 60px)",
          width: "calc(100vw)",
          margin: "auto",
          border: "none",

        }}
      ></iframe>
    )
}

export default Iframe;
